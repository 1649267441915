<!--摘要管理-->
<template>
    <div class="Abstract">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <ef-abstract-type-select v-model="form.type" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.stock.abstract.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.stock.abstract.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.abstract.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                id="printMe"
                style="width: 100%"
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="240" prop="code" v-if="showColumn('code')" />
                <el-table-column label="名称" min-width="160" prop="name" v-if="showColumn('name')" />
                <el-table-column label="类型" width="160" prop="type" key="type" v-if="showColumn('type')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | typeDesc }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="120" prop="creator" fixed="left" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    width="150"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.stock.abstract.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.stock.abstract.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import EfPagination from 'components/EfPagination';
import EfAbstractTypeSelect from 'components/EfAbstractTypeSelect';
import StockBizCommon from 'js/StockBizCommon';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Abstract',
    components: { EfPagination, EfAbstractTypeSelect, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            baseUrl: this.$efApi.stockAbstractApi.baseUrl,

            form: {
                type: '',
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        async queryList() {
            const rst = await this.$efApi.stockAbstractApi.page(this.form);
            this.tableData = rst.data.map((e) => {
                return {
                    ...e,
                };
            });
            this.total = rst.count;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleCreate() {
            Util.nameJump(this, 'menu.stock.abstract.create', ['库存管理', '摘要', '新建摘要']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.abstract.detail', ['库存管理', '摘要', '摘要详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.stock.abstract.edit', ['库存管理', '摘要', '编辑摘要'], {
                form: row,
                code: row.code,
            });
        },
        async handleDelete(row) {
            await this.$efApi.stockAbstractApi.deleteBiz(row.code);
            this.handleQuery();
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);
            UrlUtils.Export(this, '摘要管理', '/stock/abstract/export', this.form, codes);
        },
    },
    filters: {
        typeDesc(type) {
            return StockBizCommon.abstractTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.Abstract .el-form-item {
    margin-bottom: 0;
}
</style>
